import React from 'react'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'
import ReportTickets from '../../../../containers/ReportTickets'
import LayoutMain from '../../../../layout'

const DetailsTicket: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Detalle del ticket" />
      <WithPrivateRoute component={ReportTickets} />
    </LayoutMain>
  )
}

export default DetailsTicket

import moment from 'moment'
import { capitalizeFirstLetter } from '../../utils/general'

export const formatDateDetailTicket = (date: string): string => {
  const dateToMomentFormat = date.substring(0, 19)
  const day = capitalizeFirstLetter(moment(dateToMomentFormat).format('dddd'))
  const month = capitalizeFirstLetter(moment(dateToMomentFormat).format('MMM'))
  const numberDay = moment(dateToMomentFormat).format('D')
  const year = moment(dateToMomentFormat).format('YYYY')
  return `${day} ${numberDay} ${month} ${year}`
}

export const formatHourDetailTicket = (hour: string): string => {
  // const time = hour.substring(10, 19)
  return hour ? moment(hour, 'HH:mm:ss').format('hh:mm a') : 'No tiene fecha'
}

export const translatePaymentType = (paymentType: string): string => {
  switch (paymentType) {
    case 'Cash':
      return 'Efectivo'
    case 'Credit Card':
      return 'Tarjeta'
    case 'Loyalty':
      return 'Club modelorama'
    case undefined:
      return 'No tiene forma de pago'
    default:
      return paymentType
  }
}

import React from 'react'
import Amount from '../Amount'
import { List } from 'antd'
import {
  AmountContainer,
  DescriptionContainer,
  ListItem,
  ParagraphDescription,
  ParagraphProduct,
  ParagraphQuantity,
  ParagraphQuantityNumber,
  ProductImg,
  QuantityContainer,
  ReportContainer
} from './styledComponents'
import imageGeneric from '../../data/assets/icons/generic.png'

type props = {
  DataDetailTicket: any
}

const ListDetailTicket: React.FC<props> = ({ DataDetailTicket }) => {
  return (
    <ReportContainer>
      <List
        itemLayout="horizontal"
        dataSource={DataDetailTicket}
        renderItem={(item: any) => {
          return (
            <ListItem>
              <QuantityContainer>
                <ParagraphQuantity>{item.quantity}</ParagraphQuantity>
                <ParagraphQuantityNumber>x</ParagraphQuantityNumber>
                <ProductImg
                  src={item.skuImg ? item.skuImg : imageGeneric}
                  alt={item.skuName}
                />
              </QuantityContainer>
              <DescriptionContainer>
                <ParagraphProduct>{item.skuName}</ParagraphProduct>
                <ParagraphDescription>
                  {item.skuDescription}
                </ParagraphDescription>
              </DescriptionContainer>
              <AmountContainer>
                <Amount
                  amount={item.totalPrice.toFixed(2).toString()}
                  amountSize={'18'}
                  currecySize={'10'}
                  centsSize={'10'}
                />
              </AmountContainer>
            </ListItem>
          )
        }}
      />
    </ReportContainer>
  )
}

export default ListDetailTicket

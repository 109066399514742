import styled from 'styled-components'
import { List } from 'antd'

export const ReportContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  overflow-y: auto;
  max-height: 62vh;
`
export const ListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;

  &&& {
    @media all and (max-width: 480px) {
      flex-wrap: nowrap;
    }
  }
`

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ParagraphQuantity = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #1a1a1a;
  margin: 0 9px 0 0;
`
export const ParagraphQuantityNumber = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #828282;
  margin: 0 9px;

  @media all and (max-width: 400px) {
    font-size: 15px;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ParagraphProduct = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
  margin: 0;
  width: 130px;
  text-align: center;
  @media all and (max-width: 400px) {
    font-size: 12px;
  }
`
export const ParagraphDescription = styled.div`
  font-size: 14px;
  color: #828282;
  margin: 0;
  text-align: center;
  @media all and (max-width: 400px) {
    font-size: 9px;
  }
`
export const AmountContainer = styled.div`
  width: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ProductImg = styled.img``

import React from 'react'
import { List } from 'antd'
import {
  formatDateDetailTicket,
  formatHourDetailTicket,
  translatePaymentType
} from './utils'
import IconComponent from '../Icon'
import Factura from '../../data/assets/icons/factura.png'
import {
  HourContainer,
  ParagraphDate,
  ParagraphHour,
  ReportContainer
} from './styledComponents'

type props = {
  DataHeader: any[]
}
const ItemHeader: React.FC<props> = ({ DataHeader }) => {
  const ListItem = List.Item
  const ListItemMeta = List.Item.Meta
  return (
    <ReportContainer>
      <List
        itemLayout="horizontal"
        dataSource={DataHeader}
        renderItem={(item: any) => {
          return (
            <ListItem>
              <IconComponent icon={Factura} height={24} width={24} />
              <ListItemMeta
                title={`#${item.ticketID}`}
                description={`${translatePaymentType(item.paymentType)} ${
                  item.numberCard ? item.numberCard : ''
                }`}
              />
            </ListItem>
          )
        }}
      />
      <HourContainer>
        <ParagraphDate>
          {formatDateDetailTicket(DataHeader[0].dateTransaction)}
        </ParagraphDate>
        <ParagraphHour>
          {formatHourDetailTicket(DataHeader[0].timeTransaction)}
        </ParagraphHour>
      </HourContainer>
    </ReportContainer>
  )
}

export default ItemHeader

// Packages
import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { navigate } from 'gatsby'
import { useLazyQuery } from '@apollo/client'

// Component
import ListDetailTicket from '../../components/ListDetailTicket'
import ItemHeader from '../../components/DetailTicketHeader'
import { LeftOutlined } from '@ant-design/icons'
import Loading from '../../components/Shared/Loading'
import TicketNotFound from '../../components/TicketNotFound'
// utils
import { getParameter } from '../../utils/queryString'
// GQL
import { GET_TICKET_DETAIL } from '../../graphql/queries/Reports'
// Styles
import {
  TotalContainer,
  ParagraphTotal,
  StyledReportContent
} from './styledComponents'
import {
  ReportContentHeader,
  StyledReportTitle
} from '../TotalOperationsContainer/styledComponents'
import CustomButton from '../../components/Storybook/customButton'
import TotalAmount from '../../components/TotalAmount'

const initialDataDetailTicket = {
  ticketItems: [],
  ticketSummary: {
    totalTransaction: 0
  }
}

const ReportTickets: React.FC = () => {
  const ticketID = getParameter('ticketID')
  const storeId = getParameter('storeId')
  const userId = getParameter('userId')
  const [
    refetch,
    {
      data: dataDetailTicket,
      loading: loadingDetailTicket,
      error: errorDetailTicket
    }
  ] = useLazyQuery(GET_TICKET_DETAIL, {
    context: {
      clientName: 'api-instore'
    }
  })

  useEffect(() => {
    if (ticketID && storeId) {
      const variables = { ticketID, storeId, userId }
      refetch({
        variables
      })
    }
  }, [ticketID, storeId])

  useEffect(() => {
    if (!dataDetailTicket && errorDetailTicket) {
      navigate('/reports/details-tickets')
    }
  }, [dataDetailTicket])

  const { ticketSummary, ticketItems } =
    dataDetailTicket?.getTicketDetail || initialDataDetailTicket

  return (
    <>
      {loadingDetailTicket && <Loading data-testid="icon-loading" />}
      {!loadingDetailTicket && (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <ReportContentHeader>
                <CustomButton
                  id="button-icon-back-sales-report"
                  name="buttonIconBackSalesReport"
                  className="button-icon-back-sales-report"
                  dataTestId="data-test-button-icon-back-sales-report"
                  icon={<LeftOutlined />}
                  onClick={() => navigate(-1)}
                />
                <StyledReportTitle>DETALLE DE TICKET</StyledReportTitle>
              </ReportContentHeader>
            </Col>
          </Row>
          <StyledReportContent>
            {!errorDetailTicket && dataDetailTicket ? (
              <>
                <ItemHeader DataHeader={[ticketSummary]} />
                <ListDetailTicket DataDetailTicket={ticketItems} />
                <TotalContainer>
                  <ParagraphTotal>Total</ParagraphTotal>
                  <TotalAmount
                    amount={`${ticketSummary.totalTransaction}`}
                    amountSize={'36'}
                    currecySize={'18'}
                    centsSize={'18'}
                  />
                </TotalContainer>
              </>
            ) : (
              <TicketNotFound />
            )}
          </StyledReportContent>
        </>
      )}
    </>
  )
}

export default ReportTickets
